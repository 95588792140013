




























































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import {
  toRefs,
  reactive,
  PropType,
  computed,
  defineComponent,
  onBeforeUnmount,
  ref,
  watchEffect,
  watch
} from '@vue/composition-api';
import { ObjectId } from 'bson';
import _, { functions } from 'lodash';
import moment from 'moment';
import calculateProgramCompletion from '@/utils/calculateProgramCompletion';
import { ACTIVITIES } from '@/constants/monitor';
import { useDbGetters, useUserActions } from '@/store';
import rangePicker from '@/components/atoms/ADateRangePicker.vue';
import axios from 'axios';

export default defineComponent({
  name: 'Bar',
  components: {
    rangePicker
  },
  props: {
    value: {
      type: Object as PropType<Record<string, any>>,
      default: { adks: [] } as Record<string, any>
    },
    students: {
      required: true,
      type: Array as PropType<Array<Record<string, any>>>,
      default: () => []
    },
    programs: {
      required: true,
      type: Array as PropType<Array<Record<string, any>>>,
      default: () => []
    },
    errorCode: {
      required: true,
      type: Number
    },
    studentsLength: {
      required: false,
      type: Number
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    disableLoadMore: {
      type: Boolean,
      default: false
    },
    loadData: {
      type: Function,
      default: () => {}
    },
    allStudentsFetched: {
      type: Boolean,
      default: false
    },
    isFiltering: {
      type: Boolean,
      default: false
    }
  },
  setup(props, ctx) {
    const state = reactive({
      activator: null,
      attach: null,
      expand: window.innerWidth >= 760,
      makeOffer: false,
      sortByStudentData: 'Completion',
      colors: ['blue'],
      editing: null,
      activityFilter: '',
      editingIndex: -1,
      sortitems: [
        { title: 'Name' },
        { title: 'School' },
        { title: 'Team' },
        { title: 'Grade' },
        { title: 'Completion' }
      ],
      programFilter: [
        {
          text: 'All Programs',
          color: 'blue'
        }
      ],
      programFilterItems: [
        {
          text: 'All Programs',
          color: 'blue'
        }
      ],
      organizerProgram: null as Record<string, any> | null, // * used only for Organizer view of Monitor
      search: null,
      unlockInterViewKey: 1,
      intervalId: 0
    });

    const { getProgram } = useUserActions(['getProgram']);
    const filters = ['Cycle', 'Year', 'Custom'];
    const yearRangeList = ['2020-2021', '2021-2022', '2022-2023'];
    const studentFilter = ref('');
    const currentInternFilter = ref({
      name: 'Current Students',
      value: 'current_students'
    });
    const internFilters = ref([
      { name: 'Current Students', value: 'current_students' },
      { name: 'Current Interns', value: 'current_interns' },
      { name: 'Past Interns', value: 'past_interns' }
    ]);
    const selectedYear = ref('');
    const selectedRange = ref('');
    const dateRange = ref([]);
    const filterDialog = ref(false);
    const monitorType = ref('');
    const studentList = ref([]);
    const localStoredVal = ref('');
    const isSortingFilter = ref(false);
    const isActivityFilter = ref(false);
    const isProgramFilter = ref(false);
    const isCurrentInternFilter = ref(false);
    const watchInterviewDialog = ref(false);
    const ansUrl = ref('');
    const terminationReason = ref('');
    const isTerminating = ref(false);
    const terminationDialog = ref(false);

    const selectedStudent = computed({
      get: () => props.value,
      set: newValue => {
        ctx.emit('input', newValue);
      }
    });

    function openInterviewDialog(val) {
      watchInterviewDialog.value = true;
      ansUrl.value = val;
    }

    watchEffect(() => {
      if (!watchInterviewDialog.value) {
        ansUrl.value = '';
      }
    });

    const yearList = computed(() => {
      const rangeOfYears = (start, end) =>
        Array(end - start + 1)
          .fill(start)
          .map((year, index) => year + index);
      return rangeOfYears(2020, moment().year());
    });

    const isValidDate = computed(() => {
      if (dateRange.value[0] < dateRange.value[1]) {
        return true;
      }
      return false;
    });

    function setDateRange(val) {
      dateRange.value = val;
    }

    const turnOn = ref(1);
    const programToMonitor = computed(() =>
      ctx.root.$route.query ? ctx.root.$route.query.program : null
    );

    // if programId provided in query, must be Organzier view. Set filter to the program
    if (programToMonitor.value) {
      (async () => {
        let program = props.programs.find(obj => obj._id.toString() === programToMonitor.value)!;
        // Edge case (program has no participants): query for this specfic program
        if (!program) {
          program = await getProgram({
            program_id: ctx.root.$route.query.program
          });
          ctx.emit('input', null);
        }
        state.organizerProgram = program;
        state.programFilter = [
          {
            text: program.programName,
            color: 'blue'
          }
        ];
      })();
    }

    async function loadPrograms() {
      props.programs
        ?.map(doc => doc.programName)
        .forEach((name: string) => {
          state.programFilterItems.push({
            text: name,
            color: _.sample(state.colors) as string
          });
        });
    }

    watch(props, val => {
      if (val.programs) {
        loadPrograms();
      }
    });

    const sortAlphabetically = (a, b, key) => {
      const textA = key === 'school' ? a.school.name?.toUpperCase() : a[key]?.toUpperCase();
      const textB = key === 'school' ? b.school.name?.toUpperCase() : b[key]?.toUpperCase();
      if (textA < textB) return -1;
      if (textA > textB) return 1;
      return 0;
    };

    const filteredStudents = computed(() => {
      return props.students;
    });

    const filterbyDateRange = computed(() => {
      const students: any = [];
      for (let j = 0; j < filteredStudents?.value?.length; j++) {
        const element = filteredStudents.value[j];
        const d = moment(element.created).format('YYYY-MM-DD');
        element.created = d;
        students.push(element);
      }
      return students;
    });

    watchEffect(() => {
      if (studentFilter.value !== 'Custom') {
        dateRange.value = [];
      }
      if (studentFilter.value !== 'Year') {
        selectedYear.value = '';
      }
      if (studentFilter.value !== 'Cycle') {
        selectedRange.value = '';
      }
    });

    function getFilter() {
      localStoredVal.value = ctx.root.$route.query.program
        ? JSON.parse(localStorage.getItem(`setFilter_${ctx.root.$route.query.program}`))
        : JSON.parse(localStorage.getItem(`setFilter_General`));
      if (localStoredVal.value) {
        const preference: any = ctx.root.$route.query.program
          ? JSON.parse(localStorage.getItem(`setFilter_${ctx.root.$route.query.program}`))
          : JSON.parse(localStorage.getItem(`setFilter_General`));

        studentFilter.value = preference.type;

        if (preference.type === 'Cycle') {
          selectedRange.value = preference.filterValue;
        } else if (studentFilter.value === 'Year') {
          selectedYear.value = preference.filterValue;
        } else {
          dateRange.value = preference.filterValue;
        }
      }
    }

    let setFilterVal;
    let setInternFilterVal;
    let setActivityFilterVal;
    let setProgramFilterVal;
    if (ctx.root.$route.query.program) {
      monitorType.value = 'Student';
      setFilterVal = localStorage.getItem(`setSortingFilter_${ctx.root.$route.query.program}`);
      if (setFilterVal) {
        state.sortByStudentData = setFilterVal;
        isSortingFilter.value = true;
      }

      setInternFilterVal = localStorage.getItem(`setInternFilter_${ctx.root.$route.query.program}`);
      if (setInternFilterVal) {
        currentInternFilter.value = JSON.parse(setInternFilterVal);
        isCurrentInternFilter.value = true;
      }

      setActivityFilterVal = localStorage.getItem(
        `setActivityFilter_${ctx.root.$route.query.program}`
      );
      if (setActivityFilterVal) {
        state.activityFilter = setActivityFilterVal;
        ctx.emit('update:activityFilter', state.activityFilter);
        isActivityFilter.value = true;
      }

      setProgramFilterVal = localStorage.getItem(
        `setProgramFilter_${ctx.root.$route.query.program}`
      );
      if (setProgramFilterVal) {
        state.programFilter = JSON.parse(setProgramFilterVal);
        isProgramFilter.value = true;
      }
      getFilter();
    } else {
      monitorType.value = 'General';
      setFilterVal = localStorage.getItem(`setSortingFilter_General`);
      if (setFilterVal) {
        state.sortByStudentData = setFilterVal;
        isSortingFilter.value = true;
      }

      setInternFilterVal = localStorage.getItem(`setInternFilter_General`);
      if (setInternFilterVal) {
        currentInternFilter.value = JSON.parse(setInternFilterVal);
        isCurrentInternFilter.value = true;
      }

      setActivityFilterVal = localStorage.getItem(`setActivityFilter_General`);
      if (setActivityFilterVal) {
        state.activityFilter = setActivityFilterVal;
        ctx.emit('update:activityFilter', state.activityFilter);
        isActivityFilter.value = true;
      }

      setProgramFilterVal = localStorage.getItem(`setProgramFilter_General`);
      if (setProgramFilterVal) {
        state.programFilter = JSON.parse(setProgramFilterVal);
        isProgramFilter.value = true;
      }
      getFilter();
    }

    function dateFilteredStudents() {
      const f = filterbyDateRange.value;
      studentList.value = f;

      if (studentFilter.value === 'Custom' && dateRange.value?.length === 2) {
        studentList.value = f.filter(
          d => d.created >= dateRange.value[0] && d.created <= dateRange.value[1]
        );
      }

      if (studentFilter.value === 'Year' && selectedYear.value) {
        studentList.value = f.filter(
          d => d.created.split('-')[0] === JSON.stringify(selectedYear.value)
        );
      }

      if (studentFilter.value === 'Cycle' && selectedRange.value) {
        studentList.value = f.filter(
          d =>
            d.created.split('-')[0] >= selectedRange.value.split('-')[0] &&
            d.created.split('-')[0] <= selectedRange.value.split('-')[1]
        );
      }

      if (state.sortByStudentData === 'Name') {
        studentList.value = studentList.value
          .slice()
          .sort((a, b) => sortAlphabetically(a, b, 'name'));
      }

      if (state.sortByStudentData === 'Team')
        studentList.value = studentList.value
          .slice()
          .sort((a, b) => sortAlphabetically(a, b, 'teamName'));
      if (state.sortByStudentData === 'School')
        studentList.value = studentList.value
          .slice()
          .sort((a, b) => sortAlphabetically(a, b, 'school'));
      if (state.sortByStudentData === 'Grade')
        studentList.value = studentList.value
          .slice()
          .sort((a, b) => parseInt(a.grade, 10) - parseInt(b.grade, 10));
      if (state.sortByStudentData === 'Completion') {
        studentList.value = studentList.value.slice().sort((a, b) => {
          return b?.adksCompletion - a?.adksCompletion;
        });
      }

      getFilter();
    }

    watch(filterbyDateRange, newVal => {
      dateFilteredStudents();
    });
    dateFilteredStudents();

    function setFilter(type) {
      if (type === 'filterByTime') {
        const filter = {
          type: studentFilter.value
        };

        if (studentFilter.value === 'Cycle') {
          filter.filterValue = selectedRange.value;
        } else if (studentFilter.value === 'Year') {
          filter.filterValue = selectedYear.value;
        } else {
          filter.filterValue = dateRange.value;
        }

        if (monitorType.value === 'Student') {
          localStorage.setItem(
            `setFilter_${ctx.root.$route.query.program}`,
            JSON.stringify(filter)
          );
        } else {
          localStorage.setItem(`setFilter_General`, JSON.stringify(filter));
        }
        dateFilteredStudents();
        filterDialog.value = false;
        localStorage.setItem(`setSortingFilter_General`, state.sortByStudentData);
      } else if (type === 'sortByStudentData' && state.sortByStudentData !== 'Completion') {
        if (monitorType.value === 'Student') {
          localStorage.setItem(
            `setSortingFilter_${ctx.root.$route.query.program}`,
            state.sortByStudentData
          );
        } else {
          localStorage.setItem(`setSortingFilter_General`, state.sortByStudentData);
        }
        isSortingFilter.value = true;
      } else if (
        type === 'currentInternFilter' &&
        currentInternFilter?.value?.value !== 'current_students'
      ) {
        if (monitorType.value === 'Student') {
          localStorage.setItem(
            `setInternFilter_${ctx.root.$route.query.program}`,
            JSON.stringify(currentInternFilter.value)
          );
        } else {
          localStorage.setItem(
            `setInternFilter_General`,
            JSON.stringify(currentInternFilter.value)
          );
        }
        isCurrentInternFilter.value = true;
      } else if (type === 'activityFilter' && state.activityFilter !== '') {
        if (monitorType.value === 'Student') {
          localStorage.setItem(
            `setActivityFilter_${ctx.root.$route.query.program}`,
            state.activityFilter
          );
        } else {
          localStorage.setItem(`setActivityFilter_General`, state.activityFilter);
        }
        ctx.emit('update:activityFilter', state.activityFilter);
        isActivityFilter.value = true;
      } else if (
        type === 'programFilter' &&
        !(
          state?.programFilter?.length === 1 &&
          state?.programFilter?.find(obj => obj?.text === 'All Programs')
        )
      ) {
        if (monitorType.value === 'Student') {
          localStorage.setItem(
            `setProgramFilter_${ctx.root.$route.query.program}`,
            JSON.stringify(state.programFilter)
          );
        } else {
          localStorage.setItem(`setProgramFilter_General`, JSON.stringify(state.programFilter));
        }
        isProgramFilter.value = true;
      }
    }

    function removeFilter(type) {
      if (type === 'filterByTime') {
        if (monitorType.value === 'Student') {
          localStorage.removeItem(`setFilter_${ctx.root.$route.query.program}`);
        } else {
          localStorage.removeItem(`setFilter_General`);
        }

        selectedYear.value = '';
        selectedRange.value = '';
        dateRange.value = [];
        studentFilter.value = '';
        filterDialog.value = false;
        dateFilteredStudents();
      } else if (type === 'sortByStudentData') {
        if (monitorType.value === 'Student') {
          localStorage.removeItem(`setSortingFilter_${ctx.root.$route.query.program}`);
        } else {
          localStorage.removeItem(`setSortingFilter_General`);
        }
        state.sortByStudentData = 'Completion';
        isSortingFilter.value = false;
      } else if (type === 'currentInternFilter') {
        if (monitorType.value === 'Student') {
          localStorage.removeItem(`setInternFilter_${ctx.root.$route.query.program}`);
        } else {
          localStorage.removeItem(`setInternFilter_General`);
        }
        currentInternFilter.value = {
          name: 'Current Students',
          value: 'current_students'
        };
        isCurrentInternFilter.value = false;
      } else if (type === 'activityFilter') {
        if (monitorType.value === 'Student') {
          localStorage.removeItem(`setActivityFilter_${ctx.root.$route.query.program}`);
        } else {
          localStorage.removeItem(`setActivityFilter_General`);
        }
        state.activityFilter = '';
        ctx.emit('update:activityFilter', '');
        isActivityFilter.value = false;
      } else if (type === 'programFilter') {
        if (monitorType.value === 'Student') {
          localStorage.removeItem(`setProgramFilter_${ctx.root.$route.query.program}`);
        } else {
          localStorage.removeItem(`setProgramFilter_General`);
        }
        state.programFilter = [
          {
            text: 'All Programs',
            color: 'blue'
          }
        ];
        isProgramFilter.value = false;
      }
    }

    watch(
      () => state.sortByStudentData,
      val => {
        if (val === 'Completion') {
          removeFilter('sortByStudentData');
        } else {
          setFilter('sortByStudentData');
        }
      }
    );

    watch(currentInternFilter, val => {
      if (val) {
        setFilter('currentInternFilter');
        ctx.emit('clearValue');
        props.loadData(val.value);
      }
    });

    watch(
      () => state.activityFilter,
      val => {
        if (val) {
          setFilter('activityFilter');
        } else {
          removeFilter('activityFilter');
        }
      }
    );

    if (!programToMonitor.value) {
      watch(
        () => state.programFilter,
        val => {
          if (val?.length === 1 && val?.find(obj => obj?.text === 'All Programs')) {
            if (monitorType.value === 'Student') {
              localStorage.removeItem(`setProgramFilter_${ctx.root.$route.query.program}`);
            } else {
              localStorage.removeItem(`setProgramFilter_General`);
            }
            isProgramFilter.value = false;
          } else {
            setFilter('programFilter');
          }
        }
      );
    }

    const formatStudentGrade = grade => `${grade}th Grade`;

    const studentInfo = student => {
      const info = {
        School: student.school.name,
        Team: student.teamName,
        Grade: formatStudentGrade(student.grade)
      };
      const filtered = Object.keys(info)
        .filter(key => key !== state.sortByStudentData)
        .reduce((obj, key) => {
          return {
            ...obj,
            [key]: info[key]
          };
        }, {});
      return [
        ...Object.values(filtered),
        student.program,
        student.lastSaved ? student.lastSaved.toLocaleString() : 'N/A'
      ];
    };

    const handleProgramFilterInput = () => {
      if (state.programFilter.length) {
        studentList.value = props.students;
        const stds = studentList.value;
        let filters: any = [];
        filters = state.programFilter.map(p => p.text);
        if (!filters.includes('All Programs')) {
          studentList.value = stds.filter(s => filters?.includes(s.programName));
        }
      }
    };

    const edit = (index, item) => {
      if (!state.editing) {
        state.editing = item;
        state.editingIndex = index;
      } else {
        state.editing = null;
        state.editingIndex = -1;
      }
    };

    const filter = (item, queryText, itemText) => {
      if (item.header) return false;

      const hasValue = val => (val != null ? val : '');

      const text = hasValue(itemText);
      const query = hasValue(queryText);

      return text.toString().toLowerCase().indexOf(query.toString().toLowerCase()) > -1;
    };

    state.intervalId = window.setInterval(() => {
      state.unlockInterViewKey += 1;
    }, 1000);

    onBeforeUnmount(() => {
      window.clearInterval(state.intervalId);
    });

    function showMakeOfferForm(studentId: ObjectId) {
      const selectedStudent = props.students.find(student => student._id === studentId);
      if (selectedStudent) {
        selectedStudent.showMakeOfferForm = true;
        ctx.emit('input-value', selectedStudent);
      }
    }

    const showProgressBar = (sorting, student) => {
      return (
        (sorting === 'Name' || sorting === 'Completion') &&
        student.studentStatus === 'notAutoApplied'
      );
    };

    const airtableEmbed = ref(false);
    const currentProgram = ref();

    async function setCurrentProgram() {
      currentProgram.value = await getProgram({
        program_id: ctx.root.$route.query.program
      });
    }

    if (programToMonitor.value) {
      setCurrentProgram();
    }

    const airtableLink = computed(() => {
      return currentProgram?.value?.airtable_link;
    });

    async function signOffIntern() {
      try {
        isTerminating.value = true;
        const API = process.env.VUE_APP_SIGNOFF_SERVICE;
        const data = {
          type: 'terminate',
          student_id: selectedStudent.value._id.toString(),
          reason: terminationReason.value
        };
        const res = await axios.post(`${API}/signoff-intern`, data, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('apollo-token')}`
          }
        });
        if (res.data.data) {
          isTerminating.value = false;
          terminationDialog.value = false;
          selectedStudent.value.offerDetails = res.data.data?.offerDetails;
        }
      } catch (error) {
        console.log(error);
        isTerminating.value = false;
      }
    }

    return {
      ...toRefs(state),
      currentInternFilter,
      internFilters,
      terminationReason,
      signOffIntern,
      terminationDialog,
      isTerminating,
      currentProgram,
      airtableLink,
      filters,
      openInterviewDialog,
      watchInterviewDialog,
      ansUrl,
      studentList,
      filterDialog,
      monitorType,
      localStoredVal,
      isSortingFilter,
      isActivityFilter,
      isProgramFilter,
      isValidDate,
      getFilter,
      removeFilter,
      setFilter,
      filterbyDateRange,
      dateFilteredStudents,
      selectedRange,
      setDateRange,
      selectedYear,
      yearList,
      yearRangeList,
      studentFilter,
      programToMonitor,
      filteredStudents,
      // sortedStudents,
      showMakeOfferForm,
      studentInfo,
      formatStudentGrade,
      calculateProgramCompletion,
      edit,
      filter,
      activities: ACTIVITIES,
      handleProgramFilterInput,
      turnOn,
      showProgressBar,
      airtableEmbed,
      selectedStudent,
      isCurrentInternFilter
    };
  },
  methods: {
    openGuideBar() {
      this.expand = !this.expand;
    }
  }
});
