


























import { computed, ref, watchEffect } from '@vue/composition-api';

export default {
  setup(props, ctx) {
    const dates = ref([]);
    const menu = ref(false);

    const dateRange = computed(() => {
      return dates.value.join(' ~ ');
    });

    watchEffect(() => {
      if (dates.value.length === 2) {
        ctx.emit('input', dates.value);
      }
    });

    return { dates, dateRange, menu };
  }
};
